import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/ArticleLayout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Lisp is a programming language that is often used didactically in higher
education for its simplicity and minimal syntax. Sounds fancy, right? I wouldn't
know to be honest, and I didn't make it all the way through the third grade to
have you judge me.`}</p>
    <p>{`Anyways, in a few paragraphs, you'll be able to read & write Lisp! By the end of
this article we'll have implemented Lisp style evaluation with our programs
provided as JSON, or JSON Lisp™, to be precise.`}</p>
    <h1>{`Lisp Crash Course`}</h1>
    <p>{`If you're familiar with Lisp feel free to skip this crash course introduction
and jump straight to JSON Lisp where stuff gets weird.`}</p>
    <h2>{`Symbolic Expressions (S-expressions)`}</h2>
    <p>{`Lisp uses something called S-expressions for its syntax. An
`}<a parentName="p" {...{
        "href": "https://en.wikipedia.org/wiki/Expression_(computer_science)https://en.wikipedia.org/wiki/Expression_(computer_science)"
      }}>{`expression`}</a>{`
is an entity that may be evaluated to determine its value. In Lisp, everything
is an expression. Let's take a look at some Lisp Expressions that are also valid
JavaScript expressions.`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "lisp"
    }}><pre parentName="div" {...{
        "className": "language-lisp"
      }}><code parentName="pre" {...{
          "className": "language-lisp"
        }}><span parentName="code" {...{
            "className": "token comment"
          }}>{`; semicolon is how you introduce a comment in lisp`}</span>{`
`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`; (the // in JavaScript)`}</span>{`


`}<span parentName="code" {...{
            "className": "token number"
          }}>{`4`}</span>{` `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`; Value: 4`}</span>{`
`}<span parentName="code" {...{
            "className": "token string"
          }}>{`"hello"`}</span>{` `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`; Value: "hello"`}</span>{`
true `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`; Value: #t (that means true)`}</span></code></pre></div>
    <p>{`These are called primitive values. Primitive values and expressions aren't the
only concepts shared with JavaScript though, Lisp also has function calls!
However, the syntax for them is different. They are wrapped in parentheses which
makes the order of evaluation unmistakable. Arguments are separated by a space
instead of a comma.`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "lisp"
    }}><pre parentName="div" {...{
        "className": "language-lisp"
      }}><code parentName="pre" {...{
          "className": "language-lisp"
        }}><span parentName="code" {...{
            "className": "token comment"
          }}>{`; --- This is the name of the function.`}</span>{`
`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`; |`}</span>{`
`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`; |`}</span>{`
`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`; ˅`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token car"
          }}>{`+`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`1`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`2`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`; Value: 3`}</span>{`
`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`;   ˄ ˄`}</span>{`
`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`;   | |`}</span>{`
`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`;   | |`}</span>{`
`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`;   | |`}</span>{`
`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`;   ------ These are the arguments to the "+" function,`}</span>{`
`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`;       arguments are separated by a space.`}</span></code></pre></div>
    <p>{`It's a little weird to see `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`+`}</code>{` before `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`1`}</code>{` & `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`2`}</code>{`, instead of in-between it.
That's called `}<a parentName="p" {...{
        "href": "https://en.wikipedia.org/wiki/Polish_notation"
      }}>{`prefix notation`}</a>{`.
It's also a bit odd to see an operator like `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`+`}</code>{` referred to as a function. Here
are a bunch of JavaScript next to Lisp examples to make this more concrete.`}</p>
    <h3>{`Operators`}</h3>
    <p><em parentName="p">{`JavaScript`}</em></p>
    <p>{`Operators are built-in with
`}<a parentName="p" {...{
        "href": "https://en.wikipedia.org/wiki/Infix_notation#:~:text=Infix%20notation%20is%20the%20notation,plus%20sign%20in%202%20%2B%202."
      }}>{`infix notation`}</a>{`
and you only need to provide parenthesis to opt-out of the built-in
`}<a parentName="p" {...{
        "href": "https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Operators/Operator_Precedence"
      }}>{`order of operations`}</a>{`.`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "jsx"
    }}><pre parentName="div" {...{
        "className": "language-jsx"
      }}><code parentName="pre" {...{
          "className": "language-jsx"
        }}><span parentName="code" {...{
            "className": "token number"
          }}>{`3`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`*`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`3`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`+`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`3`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{` `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// 12`}</span>{`
`}<span parentName="code" {...{
            "className": "token number"
          }}>{`3`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`*`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`3`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`+`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`3`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{` `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// 18`}</span></code></pre></div>
    <p><em parentName="p">{`Lisp`}</em></p>
    <p>{`Operators are just function calls. Parenthesis are always provided which means
special syntax is not required to override built-in operator precedence, the
developer always has to provide it.`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "lisp"
    }}><pre parentName="div" {...{
        "className": "language-lisp"
      }}><code parentName="pre" {...{
          "className": "language-lisp"
        }}><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token car"
          }}>{`+`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token car"
          }}>{`*`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`3`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`3`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`3`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`; Value: 12`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token car"
          }}>{`*`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`3`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token car"
          }}>{`+`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`3`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`3`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`; Value: 18`}</span></code></pre></div>
    <h3>{`Methods`}</h3>
    <p><em parentName="p">{`JavaScript`}</em></p>
    <p>{`In JavaScript, some operations are provided as functions on the object's
prototype. The value on the left becomes `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`this`}</code>{` in the implementation of
`}<code parentName="p" {...{
        "className": "language-text"
      }}>{`toUpperCase`}</code>{`. Don't be deceived by the special method syntax, they're also
functions you can reference directly.`}</p>
    {
      /* prettier-ignore */
    }
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "jsx"
    }}><pre parentName="div" {...{
        "className": "language-jsx"
      }}><code parentName="pre" {...{
          "className": "language-jsx"
        }}><span parentName="code" {...{
            "className": "token string"
          }}>{`"hello"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`toUpperCase`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{` `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// 'HELLO'`}</span>{`
`}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`String`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`prototype`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`toUpperCase`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`call`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"h"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"i"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{` `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// 'H,I'`}</span>{`
`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// An Array? Yes. How? Coercion. Why? Life is pain.`}</span></code></pre></div>
    <p><em parentName="p">{`Lisp`}</em></p>
    <p>{`Same situation in Lisp as we had for operators, function on the left, arguments
on the right.`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "lisp"
    }}><pre parentName="div" {...{
        "className": "language-lisp"
      }}><code parentName="pre" {...{
          "className": "language-lisp"
        }}><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token car"
          }}>{`string-upcase`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"hello"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`; Value: "HELLO"`}</span></code></pre></div>
    <h3>{`Function Calls`}</h3>
    <p><em parentName="p">{`JavaScript`}</em></p>
    <p>{`Function calls have the name of the function on the left, some parentheses to
indicate calling that function, and arguments are provided in the parenthesis.`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "jsx"
    }}><pre parentName="div" {...{
        "className": "language-jsx"
      }}><code parentName="pre" {...{
          "className": "language-jsx"
        }}><span parentName="code" {...{
            "className": "token function"
          }}>{`parseInt`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"3"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{` `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// 3`}</span></code></pre></div>
    <p><em parentName="p">{`Lisp`}</em></p>
    <p>{`Same situation as operators and methods. Function on the left, arguments on the
right.`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "lisp"
    }}><pre parentName="div" {...{
        "className": "language-lisp"
      }}><code parentName="pre" {...{
          "className": "language-lisp"
        }}><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token car"
          }}>{`parse-integer`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"3"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`; Value: 3`}</span></code></pre></div>
    <p>{`Notice how in JavaScript, special syntax is used for operators, method calls &
function calls? In Lisp, they all use the same syntax! Suddenly it checks out
why the brainiacs use it.`}</p>
    <h2>{`Lisp Evaluation`}</h2>
    <p>{`Lisp evaluates a program with a strategy called
`}<a parentName="p" {...{
        "href": "https://en.wikipedia.org/wiki/Evaluation_strategy#:~:text=Applicative%20order%20evaluation%20is%20an,before%20the%20function%20is%20applied."
      }}>{`Applicative order`}</a>{`.
Applicative order means that innermost arguments are evaluated `}<em parentName="p">{`before`}</em>{` they are
provided to their function in Lisp.`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "lisp"
    }}><pre parentName="div" {...{
        "className": "language-lisp"
      }}><code parentName="pre" {...{
          "className": "language-lisp"
        }}><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token car"
          }}>{`+`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`1`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token car"
          }}>{`+`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`2`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`3`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`;  ˄     ˄`}</span>{`
`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`;  |     |`}</span>{`
`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`;  |     |`}</span>{`
`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`;  |     - Value: 5`}</span>{`
`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`;  |`}</span>{`
`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`;  Value: 1`}</span>{`
`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`; (+ 1 5)`}</span>{`
`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`; ; Value 6, All done!`}</span></code></pre></div>
    <p>{`All the Lisp examples we've seen thus far have been evaluated this way!`}</p>
    <blockquote>
      <p parentName="blockquote">{`You'll notice Applicative order seems a great deal like what you're used to in
JavaScript! JavaScript uses a similar strategy called
`}<a parentName="p" {...{
          "href": "https://en.wikipedia.org/wiki/Evaluation_strategy#Call_by_sharing"
        }}>{`Call by sharing`}</a>{`
which is very similar to Applicative order but differs in some important ways.`}</p>
    </blockquote>
    <h2>{`Special Forms`}</h2>
    <p>{`Unfortunately, there are some exceptions to this simple evaluation strategy.
Since arguments are evaluated before they are provided to their function in
Lisp, certain types of constructs can't be evaluated this way. Let's explore a
hypothetical broken `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`if-else`}</code>{` function in Lisp to clarify this.`}</p>
    <p><em parentName="p">{`Lisp`}</em></p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "lisp"
    }}><pre parentName="div" {...{
        "className": "language-lisp"
      }}><code parentName="pre" {...{
          "className": "language-lisp"
        }}><span parentName="code" {...{
            "className": "token comment"
          }}>{`; Given Applicative Order, arguments are evaluated before applying \`if\`.`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token car"
          }}>{`if-else`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token car"
          }}>{`>`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`2`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`1`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token car"
          }}>{`+`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`5`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`5`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token car"
          }}>{`+`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`2`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`2`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`; Evaluate the arguments first.`}</span>{`
`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`;           |       |       |`}</span>{`
`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`;           ˅       ˅       ˅`}</span>{`
`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`;(if-else  true    10       4)`}</span>{`
`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`;                           ˄`}</span>{`
`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`;                           |`}</span>{`
`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`;                           |`}</span>{`
`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`;                           - Cripes! We executed the else branch`}</span>{`
`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`;                             even though we shouldn't have!`}</span>{`
`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`; Value: 10`}</span></code></pre></div>
    <p>{`Since the arguments were evaluated before they were provided to the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`if-else`}</code>{`
function we end up evaluating the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`else`}</code>{` branch even if we shouldn't. Here is a
JavaScript interface to this problem:`}</p>
    <p><em parentName="p">{`JavaScript`}</em></p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "jsx"
    }}><pre parentName="div" {...{
        "className": "language-jsx"
      }}><code parentName="pre" {...{
          "className": "language-jsx"
        }}><span parentName="code" {...{
            "className": "token comment"
          }}>{`// By the time ifElse gets the arguments, it's too`}</span>{`
`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// late. As they say, "Young Herc was mortal now."`}</span>{`
`}<span parentName="code" {...{
            "className": "token function"
          }}>{`ifElse`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`2`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`1`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`5`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`+`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`5`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`2`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`+`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`2`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span></code></pre></div>
    <p>{`To solve this problem Lisp keeps the same syntax but treats the evaluation for
those entities differently. This handful of specially interpreted expressions
are called
`}<a parentName="p" {...{
        "href": "https://courses.cs.northwestern.edu/325/readings/special-forms.php#:~:text=What%20are%20special%20forms%3F,%2C%20if%20any%2C%20are%20evaluated."
      }}>{`special forms`}</a>{`.
Special forms look like normal function calls, however, the interpreter treats
them, specially.`}</p>
    <p><em parentName="p">{`Lisp`}</em></p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "lisp"
    }}><pre parentName="div" {...{
        "className": "language-lisp"
      }}><code parentName="pre" {...{
          "className": "language-lisp"
        }}><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token keyword"
          }}>{`if`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token car"
          }}>{`>`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`2`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`1`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token car"
          }}>{`+`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`5`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`5`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token car"
          }}>{`+`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`2`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`2`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`;      ˄`}</span>{`
`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`;      |`}</span>{`
`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`;      Lisp understands \`if\` is different,`}</span>{`
`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`;      it evaluates the conditional first`}</span>{`
`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`;      to determine what to do next.`}</span>{`
`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`;(if true (+ 5 5) (+ 2 2))`}</span>{`
`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`;            ˄`}</span>{`
`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`;            |`}</span>{`
`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`;            Now this will be evaluated but`}</span>{`
`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`;            (+ 2 2) will never be evaluated.`}</span>{`
`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`; Value: 10`}</span></code></pre></div>
    <p>{`See how Lisp knows that `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`if`}</code>{` is different? Special even? Worthy of a noble
treatment indeed!`}</p>
    <p>{`Alright, that's everything we need to know to start our JSON Lisp journey.`}</p>
    <h1>{`Lisp in JSON`}</h1>
    <p>{`Let's migrate our Lisp examples into a JSON syntax.`}</p>
    <p>{`Our basic primitive values, like `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`4`}</code>{`, `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`"Hello"`}</code>{` and `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`true`}</code>{` hold-up just fine as
values in JSON. So we don't need to do anything there. Things start to get
interesting when we look at expressing function calls in JSON. Which, if you
remember from our Lisp crash course above, buys us operators and methods too.`}</p>
    <h2>{`Function Calls`}</h2>
    <p>{`To represent function calls in JSON let's take a look at our addition example.`}</p>
    <p><em parentName="p">{`Lisp`}</em></p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "lisp"
    }}><pre parentName="div" {...{
        "className": "language-lisp"
      }}><code parentName="pre" {...{
          "className": "language-lisp"
        }}><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token car"
          }}>{`+`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`1`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`2`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span></code></pre></div>
    <p>{`Unfortunately for us the elegant parenthesis, `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`()`}</code>{`, are invalid symbols in JSON.
Fortunately, their rectilinear cousins `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`[]`}</code>{` are totally valid. So let's swap
those. While we're at it whitespace is insignificant and will be thrown out by
most JSON deserializers/parsers, so we'll use the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`,`}</code>{` as our separator. These
two modifications alone, give us a mechanism for expressing the above program in
JSON.`}</p>
    <p><em parentName="p">{`JSON Lisp`}</em></p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "json"
    }}><pre parentName="div" {...{
        "className": "language-json"
      }}><code parentName="pre" {...{
          "className": "language-json"
        }}><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"+"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`1`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`2`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span></code></pre></div>
    <p>{`Let's look at our other examples in light of these rules.`}</p>
    <h2>{`Example JSON Lisp Programs`}</h2>
    <p><em parentName="p">{`JSON Lisp Operators`}</em></p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "json"
    }}><pre parentName="div" {...{
        "className": "language-json"
      }}><code parentName="pre" {...{
          "className": "language-json"
        }}><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"*"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`3`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"+"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`3`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`3`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span></code></pre></div>
    <p><em parentName="p">{`JSON Lisp Methods`}</em></p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "json"
    }}><pre parentName="div" {...{
        "className": "language-json"
      }}><code parentName="pre" {...{
          "className": "language-json"
        }}><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"uppercase"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"hello"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span></code></pre></div>
    <p><em parentName="p">{`JSON Lisp Function Calls`}</em></p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "json"
    }}><pre parentName="div" {...{
        "className": "language-json"
      }}><code parentName="pre" {...{
          "className": "language-json"
        }}><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"alert"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"uppercase"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"Life is dope."`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span></code></pre></div>
    <p><em parentName="p">{`JSON Lisp Special Forms`}</em></p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "json"
    }}><pre parentName="div" {...{
        "className": "language-json"
      }}><code parentName="pre" {...{
          "className": "language-json"
        }}><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"if"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`">"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`2`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`1`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"+"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`5`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`5`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"+"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`2`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`2`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span>{`
`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`//         ˄             ˄           ˄`}</span>{`
`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`//         |             |           |`}</span>{`
`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`//         |             |           |`}</span>{`
`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`//     condition      if true     otherwise`}</span></code></pre></div>
    <p>{`Turns out that Lisp's simple S-expression syntax make it pretty easy to model`}<br parentName="p"></br>{`
`}{`in JSON.`}</p>
    <h1>{`JSON Lisp Implementation`}</h1>
    <p>{`Our JSON Lisp implementation will be done in TypeScript.`}</p>
    <blockquote>
      <p parentName="blockquote">{`TypeScript, so hot right now. - Mugatu`}</p>
    </blockquote>
    <h2>{`Lexical Analysis & Parsing`}</h2>
    <p>{`First things first, programming language implementations typically need a
mechanism for taking the source program, a string of text, and turning it into a
structure that the interpreter or compiler can work with, this is called a
`}<a parentName="p" {...{
        "href": "https://en.wikipedia.org/wiki/Parse_tree#:~:text=A%20parse%20tree%20or%20parsing,to%20some%20context%2Dfree%20grammar."
      }}>{`parse tree`}</a>{`
The parse tree is often subsequently converted into an
`}<a parentName="p" {...{
        "href": "https://en.wikipedia.org/wiki/Abstract_syntax_tree"
      }}>{`abstract syntax tree`}</a>{` for
further analysis, however, our implementation will interpret the parse tree
directly. So what do we need for the lexer and parser? Get ready.`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "jsx"
    }}><pre parentName="div" {...{
        "className": "language-jsx"
      }}><code parentName="pre" {...{
          "className": "language-jsx"
        }}><span parentName="code" {...{
            "className": "token keyword"
          }}>{`export`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span>{` parse `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token constant"
          }}>{`JSON`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`parse`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span></code></pre></div>
    <p>{`Yup, `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`JSON.parse`}</code>{`. Takes care of our lexing and parsing. It converts values
nicely and even gives useful syntax errors. Out of the gate, our JSON Lisp will
have a great headstart for other host language implementations!`}</p>
    <blockquote>
      <p parentName="blockquote">{`The growth of modern streaming architectures & streaming JSON parsers raises
an interesting possibility I'd love to explore further (or hear about if you
explore it) around streaming evaluation, or the ability to evaluate the
program as it is downloaded and parsed. This would make JSON Lisp or something
like it a really interesting general-purpose format for primarily server
rendered architectures with persistent views that emit instructions to the
client using Web Sockets like
`}<a parentName="p" {...{
          "href": "https://github.com/phoenixframework/phoenix_live_view"
        }}>{`Phoenix Live View`}</a>{`.`}</p>
    </blockquote>
    <p>{`Ok, since we have a type system at our disposal we may as well add some nice
type annotations for JSON.`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "jsx"
    }}><pre parentName="div" {...{
        "className": "language-jsx"
      }}><code parentName="pre" {...{
          "className": "language-jsx"
        }}>{`type Json `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`
  `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`|`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`null`}</span>{`
  `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`|`}</span>{` boolean
  `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`|`}</span>{` number
  `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`|`}</span>{` string
  `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`|`}</span>{` Json`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span>{`
  `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`|`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span>{`prop`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` string`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` Json `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`

`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`export`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span>{` parse `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`source`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` string`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token parameter"
          }}>{`Json`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{` `}<span parentName="code" {...{
            "className": "token constant"
          }}>{`JSON`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`parse`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`source`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span></code></pre></div>
    <h2>{`Evaluation`}</h2>
    <p>{`To evaluate a JSON Lisp expression we'll provide a function called `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`evaluate`}</code>{`.
Which will take in a JSON Lisp `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`expression`}</code>{`, the return value of parse above,
and an `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`environment`}</code>{`. The `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`environment`}</code>{` is the context the program will be run
in. The `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`environment`}</code>{` will contain the implementations of our built-in operators
and provide us a place for storing user-land function definitions and values.`}</p>
    <p>{`You can think of the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`environment`}</code>{` as the global namespace in a browser window.
There are some built-in functions you can call that are implemented in the host
language like `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`alert`}</code>{`. It also contains functions that you put there.`}</p>
    <p>{`So our `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`Environment`}</code>{` type will be a map of identifiers (global names) to either
`}<code parentName="p" {...{
        "className": "language-text"
      }}>{`JSON`}</code>{` (user-land implementations) or to JavaScript `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`Function`}</code>{`s (host language
provided functions).`}</p>
    {
      /* prettier-ignore */
    }
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "jsx"
    }}><pre parentName="div" {...{
        "className": "language-jsx"
      }}><code parentName="pre" {...{
          "className": "language-jsx"
        }}><span parentName="code" {...{
            "className": "token comment"
          }}>{`// The environment is a map of identifiers to values. `}</span>{`
type Environment `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{` 
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span>{`name`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` string`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token constant"
          }}>{`JSON`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`|`}</span>{` Function 
`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`//                 ˄       ˄ `}</span>{`
`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`//                 |       |`}</span>{`
`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`//                 |       |`}</span>{`
`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`//                user    host`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`

`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`export`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span>{` `}<span parentName="code" {...{
            "className": "token function-variable function"
          }}>{`evaluate`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`
  `}<span parentName="code" {...{
            "className": "token parameter"
          }}>{`expression`}<span parentName="span" {...{
              "className": "token operator"
            }}>{`:`}</span>{` Json`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{`
  environment`}<span parentName="span" {...{
              "className": "token operator"
            }}>{`:`}</span>{` Environment`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// TODO Write the evaluator`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span></code></pre></div>
    <h3>{`Applicative Order Evaluation`}</h3>
    <p>{`In Applicative Order evaluation, each expression is evaluated before providing
it to its function. For our base case, we'll evaluate each of the arguments
along with the function recursively as we walk down the expression tree, and
we'll apply our evaluated procedures on the way up (in Lisp the function is
often called the procedure).`}</p>
    <p>{`When we encounter a string, we'll look up its
`}<a parentName="p" {...{
        "href": "https://en.wikipedia.org/wiki/Subroutine"
      }}>{`function`}</a>{` implementation in the
environment often called a procedure in Lisp, or by the OG programmers of 20+
years ago.`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "jsx"
    }}><pre parentName="div" {...{
        "className": "language-jsx"
      }}><code parentName="pre" {...{
          "className": "language-jsx"
        }}><span parentName="code" {...{
            "className": "token keyword"
          }}>{`export`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span>{` `}<span parentName="code" {...{
            "className": "token function-variable function"
          }}>{`evaluate`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token parameter"
          }}>{`expression`}<span parentName="span" {...{
              "className": "token operator"
            }}>{`:`}</span>{` Json`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{` environment`}<span parentName="span" {...{
              "className": "token operator"
            }}>{`:`}</span>{` Environment`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// When we encounter an expression, \`[ ]\``}</span>{`
  `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`if`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`Array`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`isArray`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`expression`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
    `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// Evaluate each of the sub-expressions`}</span>{`
    `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span>{` result `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` expression`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`map`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token parameter"
          }}>{`expression`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{`
      `}<span parentName="code" {...{
            "className": "token function"
          }}>{`evaluate`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`expression`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` environment`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
    `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`

    `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// If there is nothing to apply, we have a value.`}</span>{`
    `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`if`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`result`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`length `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`===`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`1`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
      `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`return`}</span>{` result`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`0`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
    `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`else`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
      `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// Retrieve the procedure from the environment`}</span>{`
      `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span>{` procedure `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` result`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`0`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
      `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// Apply it with the evaluated arguments`}</span>{`
      `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`return`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`procedure`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`...`}</span>{`result`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`slice`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`1`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
    `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`else`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
    `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// Look up strings in the environment as references.`}</span>{`
    `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`if`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`
      `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`typeof`}</span>{` expression `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`===`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"string"`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`&&`}</span>{`
      environment`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`hasOwnProperty`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`expression`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
    `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
      `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`return`}</span>{` environment`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span>{`expression`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
    `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`

    `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// Return values.`}</span>{`
    `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`return`}</span>{` expression`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span></code></pre></div>
    <p>{`This is enough to implement our `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`operator`}</code>{` examples! In fact, with this small
implementation we can implement the 1.1 exercises from MIT's famous
`}<a parentName="p" {...{
        "href": "https://mitpress.mit.edu/sites/default/files/sicp/index.html"
      }}>{`Structure & Interpretation of Computer Programs`}</a>{`.`}</p>
    {
      /* prettier-ignore */
    }
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "jsx"
    }}><pre parentName="div" {...{
        "className": "language-jsx"
      }}><code parentName="pre" {...{
          "className": "language-jsx"
        }}><span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span>{` `}<span parentName="code" {...{
            "className": "token function-variable function"
          }}>{`add`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token parameter"
          }}><span parentName="span" {...{
              "className": "token operator"
            }}>{`...`}</span>{`args`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{` args`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`reduce`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token parameter"
          }}>{`x`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{` y`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{` x `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`+`}</span>{` y`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span>{` `}<span parentName="code" {...{
            "className": "token function-variable function"
          }}>{`subtract`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token parameter"
          }}><span parentName="span" {...{
              "className": "token operator"
            }}>{`...`}</span>{`args`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`args`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`length `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`===`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`1`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`?`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`0`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` args`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`0`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` args`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`reduce`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token parameter"
          }}>{`x`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{` y`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{` x `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`-`}</span>{` y`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span>{` `}<span parentName="code" {...{
            "className": "token function-variable function"
          }}>{`division`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token parameter"
          }}><span parentName="span" {...{
              "className": "token operator"
            }}>{`...`}</span>{`args`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`args`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`length `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`===`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`1`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`?`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`1`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` args`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`0`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` args`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`reduce`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token parameter"
          }}>{`x`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{` y`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{` x `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`/`}</span>{` y`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span>{` `}<span parentName="code" {...{
            "className": "token function-variable function"
          }}>{`multiplication`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token parameter"
          }}><span parentName="span" {...{
              "className": "token operator"
            }}>{`...`}</span>{`args`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{` args`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`reduce`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token parameter"
          }}>{`x`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{` y`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{` x `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`*`}</span>{` y`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`1`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`

`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// We provide the environment that references read from here.`}</span>{`
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span>{` defaultEnvironment `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"+"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` add`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
  `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"-"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` subtract`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
  `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"/"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` division`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
  `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"*"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` multiplication`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`

`}<span parentName="code" {...{
            "className": "token function"
          }}>{`expect`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`
  `}<span parentName="code" {...{
            "className": "token function"
          }}>{`evaluate`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`10`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` defaultEnvironment`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`toEqual`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`10`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token function"
          }}>{`expect`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`
  `}<span parentName="code" {...{
            "className": "token function"
          }}>{`evaluate`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"+"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`5`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`3`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`4`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` defaultEnvironment`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`toEqual`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`12`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token function"
          }}>{`expect`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`
  `}<span parentName="code" {...{
            "className": "token function"
          }}>{`evaluate`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"-"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`9`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`1`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` defaultEnvironment`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`toEqual`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`8`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token function"
          }}>{`expect`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`
  `}<span parentName="code" {...{
            "className": "token function"
          }}>{`evaluate`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"/"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`6`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`2`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` defaultEnvironment`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`toEqual`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`3`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token function"
          }}>{`expect`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`
  `}<span parentName="code" {...{
            "className": "token function"
          }}>{`evaluate`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"+"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"*"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`2`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`4`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"-"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`4`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`6`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` defaultEnvironment`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`toEqual`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`
  `}<span parentName="code" {...{
            "className": "token number"
          }}>{`6`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token function"
          }}>{`expect`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`
  `}<span parentName="code" {...{
            "className": "token function"
          }}>{`evaluate`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"+"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"*"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`2`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`4`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"-"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`4`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`6`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` defaultEnvironment`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`toEqual`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`
  `}<span parentName="code" {...{
            "className": "token number"
          }}>{`6`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span></code></pre></div>
    <h3>{`Other Functions`}</h3>
    <p>{`With that, the pattern for adding `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`functions`}</code>{` to call is to provide their
implementations on the environment and then reference them by name in the left
most position in our JSON Lisp S-Expression.`}</p>
    <p>{`For example, we could add uppercase to our environment:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "jsx"
    }}><pre parentName="div" {...{
        "className": "language-jsx"
      }}><code parentName="pre" {...{
          "className": "language-jsx"
        }}><span parentName="code" {...{
            "className": "token function"
          }}>{`evaluate`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"uppercase"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"Hello world!"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  `}<span parentName="code" {...{
            "className": "token function-variable function"
          }}>{`uppercase`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token parameter"
          }}>{`str`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{` str`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`toUpperCase`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span></code></pre></div>
    <blockquote>
      <p parentName="blockquote">{`Using strings to double as identifiers creates a weird class of bugs, what if
I mean the string `}<code parentName="p" {...{
          "className": "language-text"
        }}>{`"uppercase"`}</code>{` instead of the function `}<code parentName="p" {...{
          "className": "language-text"
        }}>{`uppercase`}</code>{`? To solve
this a special form could be introduced for reading identifiers or expressing
strings. E.G. `}<code parentName="p" {...{
          "className": "language-text"
        }}>{`["Text", "Hello world!"]`}</code></p>
    </blockquote>
    <h3>{`Special Forms`}</h3>
    <p>{`As we explored above, certain constructs can't be implemented as functions using
strict applicative order evaluation, for example, `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`if`}</code>{`. Let's explore adding a
special form for handing `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`if`}</code>{`.`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "jsx"
    }}><pre parentName="div" {...{
        "className": "language-jsx"
      }}><code parentName="pre" {...{
          "className": "language-jsx"
        }}><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"if"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token boolean"
          }}>{`true`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"+"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`2`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`2`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`0`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`//       ˄         ˄           ˄`}</span>{`
`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`//       |         |           |`}</span>{`
`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`//       |         |          Else branch`}</span>{`
`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`//       |         Run this only if the predicate is true`}</span>{`
`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`//       Evaluate the predicate first`}</span>{`
`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// returns 4`}</span></code></pre></div>
    <p>{`The way we'll go about this is checking to see if the expression reflects one of
our special forms before we evaluate. If it does we'll evaluate it specially, if
it doesn't we'll evaluate and apply as usual!`}</p>
    <p>{`In the case of `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`if`}</code>{`, we'll first evaluate its predicate. Depending on the value
returned we'll then subsequently evaluate the correct branch.`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "jsx"
    }}><pre parentName="div" {...{
        "className": "language-jsx"
      }}><code parentName="pre" {...{
          "className": "language-jsx"
        }}><span parentName="code" {...{
            "className": "token keyword"
          }}>{`export`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span>{` `}<span parentName="code" {...{
            "className": "token function-variable function"
          }}>{`evaluate`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token parameter"
          }}>{`expression`}<span parentName="span" {...{
              "className": "token operator"
            }}>{`:`}</span>{` Json`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{` environment`}<span parentName="span" {...{
              "className": "token operator"
            }}>{`:`}</span>{` Environment`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`if`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`Array`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`isArray`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`expression`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
    `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span>{` procedure `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` expression`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`0`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`

    `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// Look for special forms based on the first array entry.`}</span>{`
    `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`switch`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`procedure`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
      `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// Check if we have a special form!`}</span>{`
      `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`case`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"if"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
        `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// Retrieve the predicate`}</span>{`
        `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span>{` predicate `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` expression`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`1`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
        `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// Evaluate the predicate in the environemnt`}</span>{`
        `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`if`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`evaluate`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`predicate`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` environment`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
          `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// If it is true evaluate the first branch`}</span>{`
          `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`return`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`evaluate`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`expression`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`2`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` environment`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
        `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`else`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
          `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// If it is false evaluate the false branch,`}</span>{`
          `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// if one is provided.`}</span>{`
          `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`if`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`expression`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`3`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
            `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`return`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`evaluate`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`expression`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`3`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` environment`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
          `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`else`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
            `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`return`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`null`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
          `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
        `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
      `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
      `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// Time for some applicative order evaluation Ma!`}</span>{`
      `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`default`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
        `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span>{` result `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` expression`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`map`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token parameter"
          }}>{`expression`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{`
          `}<span parentName="code" {...{
            "className": "token function"
          }}>{`evaluate`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`expression`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` environment`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
        `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`

        `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`if`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`result`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`length `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`===`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`1`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
          `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`return`}</span>{` result`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`0`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
        `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`else`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
          `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span>{` procedure `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` result`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`0`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
          `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`return`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`procedure`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`...`}</span>{`result`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`slice`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`1`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
        `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
      `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
    `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`else`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
    `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// Nothing changed here.`}</span>{`
    `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`if`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`
      `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`typeof`}</span>{` expression `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`===`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"string"`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`&&`}</span>{`
      environment`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`hasOwnProperty`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`expression`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
    `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
      `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`return`}</span>{` environment`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span>{`expression`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
    `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
    `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`return`}</span>{` expression`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span></code></pre></div>
    <p>{`Sweet! Now we've got support for conditional branching in our JSON Lisp!`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "jsx"
    }}><pre parentName="div" {...{
        "className": "language-jsx"
      }}><code parentName="pre" {...{
          "className": "language-jsx"
        }}><span parentName="code" {...{
            "className": "token function"
          }}>{`expect`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`evaluate`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"if"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token boolean"
          }}>{`true`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"+"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`1`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`1`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`toEqual`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`2`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token function"
          }}>{`expect`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`evaluate`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"if"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token boolean"
          }}>{`false`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`1`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"+"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`1`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`2`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`toEqual`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`3`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// If an else branch isn't provided return a null value.`}</span>{`
`}<span parentName="code" {...{
            "className": "token function"
          }}>{`expect`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`evaluate`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"if"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token boolean"
          }}>{`false`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`1`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`toEqual`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token keyword"
          }}>{`null`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span></code></pre></div>
    <p>{`With, that the pattern for adding special forms is to add additional branches to
the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`switch`}</code>{` statement.`}</p>
    <h2>{`Summary`}</h2>
    <p>{`At this point, our JSON Lisp implementation is in a place where we can add more
special forms and provide new function implementations. I hope that this toy
implementation illuminated some dark corners about how programming languages
work. At the least, I hope you had fun!`}</p>
    <p>{`You can play with `}<a parentName="p" {...{
        "href": "https://github.com/iammerrick/json-lisp"
      }}>{`JSON Lisp here`}</a>{` by
cloning the repository and running `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`yarn test`}</code>{`.`}</p>
    <p>{`Some suggestions for taking this a step further:`}</p>
    <ol>
      <li parentName="ol">{`Implement `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`define`}</code>{` for creating new abstractions on the environment,
variables, and functions! Can prototypical inheritance be used to mimic a
stack for function variable scoping?`}</li>
      <li parentName="ol">{`Implement additional special forms!`}</li>
      <li parentName="ol">{`Someone please explore streaming evaluation and tell me how it goes!`}</li>
    </ol>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      